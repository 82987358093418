import React from "react";
import { render } from "react-dom";

import { App } from "./app/App";
import "./index.css";

import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") disableReactDevTools();

render(<App />, document.getElementById("root"));
